<template>
  <div class="sheets">
    <!-- <div>
      <CButton class="user-registration-btn" color="success">印刷</CButton>
      <CButton
        class="user-registration-btn"
        color="primary"
        @click="goToUserPage"
        >戻る</CButton
      >
    </div> -->
    <div id="sheet" class="sheet">
      <div class="print-page-container">
        <div class="print-page-left-container">
          <div class="left-container-top">
            <div class="name-container">
              <div class="name" id="name">{{ lastName }} {{ firstName }} 様</div>
              <div>
                <div class="volume">Vol.1</div>
                <span class="small">{{ date }}</span>
              </div>
            </div>
            <div class="target-container print-border-radius serif">
              <div id="target">目標:{{ target }}</div>
              <div id="deadline">いつまでに:{{ deadline }}</div>
              <div class="target">
                <div id="whatToDo1">なにをする:{{ whatToDo1 }}</div>
                <div id="whatToDo2">{{ whatToDo2 }}</div>
                <div id="whatToDo3">{{ whatToDo3 }}</div>
              </div>
            </div>
          </div>
          <div class="left-container-middle">
            <div class="weight-chart">
              <VChart :autoresize="true" :option="weightChartOptions" class="chart" />
            </div>
            <div class="body-fat-chart">
              <!-- <BodyFatChart /> -->
              <VChart :autoresize="true" :option="bodyFatChartOptions" class="chart" />
            </div>
            <div class="muscle-chart">
              <!-- <MuscleChart /> -->
              <VChart :autoresize="true" :option="muscleChartOptions" class="chart" />
            </div>
          </div>
          <div class="left-container-bottom">
            <div class="left-container-bottom-title">体脂肪率による分類</div>
            <div class="body-fat-percentage-table">
              <div class="body-fat-percentage-table-header">
                <div class="table-left"></div>
                <div class="table-head">過剰肥満/<br />危険レベル</div>
                <div class="table-head">肥満</div>
                <div class="table-head">隠れ肥満</div>
                <div class="table-head">適度な<br />体脂肪率</div>
                <div class="table-head">やせている</div>
                <div class="table-head">痩せすぎ/<br />アスリート</div>
              </div>
              <div class="table-female">
                <div class="table-left">女性</div>
                <div class="table-content">35.0%以上</div>
                <div class="table-content">30.0〜<br />34.9%</div>
                <div class="table-content">25.0〜<br />29.9%</div>
                <div class="table-content">22.1〜<br />24.9%</div>
                <div class="table-content">17.1〜<br />22.0%</div>
                <div class="table-content">17.0%以下</div>
              </div>
              <div class="table-male">
                <div class="table-left">男性</div>
                <div class="table-content">25.0%以上</div>
                <div class="table-content">20.1〜<br />24.9%</div>
                <div class="table-content">18.1〜<br />20.0%</div>
                <div class="table-content">15.1〜<br />18.0%</div>
                <div class="table-content">10.1〜<br />15.0%</div>
                <div class="table-content">10.0%以下</div>
              </div>
            </div>
          </div>
        </div>
        <div class="print-page-right-container">
          <div class="right-container-top">
            <img src="@/assets/img/foodImage.jpg" alt="" />
          </div>
          <div class="right-container-middle">
            <div class="right-container-middle-left print-border-radius">
              <div class="title" id="title1">{{ firstTitle }}</div>
              <div class="contents serif" id="content1">
                {{ firstContent }}
              </div>
            </div>
            <div class="right-container-middle-right print-border-radius">
              <div class="title" id="title2">{{ secondTitle }}</div>
              <div class="contents serif" id="content2">
                {{ secondContent }}
              </div>
            </div>
          </div>
          <div class="right-container-bottom">
            <div class="right-container-bottom-left print-border-radius">
              <div class="title" id="title3">{{ thirdTitle }}</div>
              <div class="contents serif" id="content3">{{ thirdContent }}</div>
            </div>
            <div class="right-container-bottom-right">
              <div class="right-container-bottom-right-top print-border-radius">
                <div class="title" id="title4">{{ fourthTitle }}</div>
                <div class="contents serif" id="content4">
                  {{ fourthContent }}
                </div>
              </div>
              <div class="right-container-bottom-right-bottom">
                <div class="right-container-bottom-img">
                  <img src="@/assets/img/etsuko.jpg" alt="" />
                </div>
                <div>担当管理栄養士<br />手嶋 英津子</div>
                <div class="right-container-bottom-logo">
                  <img src="@/assets/img/logo.png" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
import { LineChart, ScatterChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import firebase from "firebase";
import "zrender/lib/svg/svg";

const db = firebase.firestore();

use([
  CanvasRenderer,
  SVGRenderer,
  LineChart,
  ScatterChart,
  TitleComponent,
  GridComponent,
  LegendComponent,
  AxisPointerComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  BarChart,
  TooltipComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
]);

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;
const date = new Date().getDate();

export default {
  name: "PrintPage",
  components: {
    VChart,
  },
  data() {
    return {
      lastName: "",
      firstName: "",
      // volume: "",
      date: `${year}/${month}/${date}`,
      target: "",
      deadline: "",
      whatToDo1: "",
      whatToDo2: "",
      whatToDo3: "",
      firstTitle: "",
      secondTitle: "",
      thirdTitle: "",
      fourthTitle: "",
      firstContent: "",
      secondContent: "",
      thirdContent: "",
      fourthContent: "",

      weightData: "",
      weightDateData: "",
      bodyFatPercentageData: "",
      bodyFatPercentageDateData: "",
      muscleData: "",
      muscleDateData: "",

      // weightChartの値
      weightChartOptions: {
        color: ["#FF7C5E"],
        title: {
          text: "体重",
        },
        grid: {
          top: 35,
          right: 5,
          left: 40,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: this.$route.query.weightDateData,
        },
        yAxis: {
          type: "value",
          // offset: -5,
          min: function (value) {
            return value.min;
          },
        },
        series: {
          data: this.$route.query.weightData,
          type: "line",
          connectNulls: true,
        },
      },
      bodyFatChartOptions: {
        color: ["#6B89FF"],
        title: {
          text: "体脂肪率",
        },
        grid: {
          top: 35,
          right: 5,
          left: 40,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: this.$route.query.bodyFatPercentageDateData,
        },
        yAxis: {
          type: "value",
          min: function (value) {
            return value.min;
          },
        },
        series: {
          data: this.$route.query.bodyFatPercentageData,
          type: "line",
          connectNulls: true,
        },
      },
      muscleChartOptions: {
        color: ["#FCB765"],
        title: {
          text: "筋肉量",
        },
        grid: {
          top: 35,
          right: 5,
          left: 40,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: this.$route.query.muscleDateData,
        },
        yAxis: {
          type: "value",
          min: function (value) {
            return value.min;
          },
        },
        series: {
          data: this.$route.query.muscleData,
          type: "line",
          connectNulls: true,
        },
      },
    };
  },
  created() {
    // db.collection("users")
    //   .doc("sT1X9H96IeZCnPQadMNsm7z48L62")
    //   .collection("record")
    //   .orderBy("createdAt", "asc")
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       this.weightData += "weightData[]=" + doc.data().weight + "&";
    //       this.weightDateData += "weightDateData[]=" + doc.data().date + "&";
    //       this.bodyFatPercentageData +=
    //         "bodyFatPercentageData[]=" + doc.data().bodyFatPercentage + "&";
    //       this.bodyFatPercentageDateData +=
    //         "bodyFatPercentageDateData[]=" + doc.data().date + "&";
    //       this.muscleData += "muscleData[]=" + doc.data().muscle + "&";
    //       this.muscleDateData += "muscleDateData[]=" + doc.data().date + "&";
    //     });
    //     console.log(this.weightData);
    //     console.log(this.weightDateData);
    //     console.log(this.bodyFatPercentageData);
    //     console.log(this.bodyFatPercentageDateData);
    //     console.log(this.muscleData);
    //     console.log(this.muscleDateData);
    //   });
  },
  methods: {
    goToUserPage() {
      this.$router.push("/user-page");
    },
  },
};
</script>
<style scoped>
/* pag-settings */
@media print {
  .sheets > :not(.sheet) {
    display: none;
    -webkit-print-color-adjust: exact;
  }
}
@page {
  size: landscape;
  -webkit-print-color-adjust: exact;
}
@media screen {
  /* mm単位で指定しているけど、vueコンポ側はpx単位なので、無理にmmにしなくてもいいかも。解像度の違いでハマるかも */
  .sheet {
    width: 297mm;
    height: 210mm;
    /* 設定しなくてもいいかも。あまり印刷画面に似せすぎると、些細な違いがバグに見えてしまう */
    /* margin: 5mm;
    padding: 5mm; */
    /* background: white; */
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  }
}

.sheets {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* width: 100vw; */
  /* -webkit-print-color-adjust: exact; */
  font-family: "Meiryo UI";
}
.sheet {
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-print-color-adjust: exact;
  font-size: 12px;
  font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO";
}

/* common-settings */
.print-border-radius {
  border-radius: 10px;
}
.title {
  font-weight: bold;
  font-size: 16px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.serif {
  font-family: serif;
}
input {
  caret-color: transparent;
  border: none;
  width: 100%;
  background-color: rgb(226, 240, 217);
}
input:focus {
  outline: none;
}

/* left-container */
.print-page-container {
  display: flex;
  background-color: #fff;
}
.print-page-left-container,
.print-page-right-container {
  width: 50%;
}
.print-page-left-container {
  padding-right: 0.3rem;
}
.print-page-right-container {
  padding-left: 0.3rem;
}
.left-container-top {
  width: 100%;
  height: 15%;
}
.left-container-middle {
  width: 100%;
  height: 65%;
  background-color: #fff;
}
.left-container-bottom {
  width: 100%;
  height: 20%;
}
.left-container-bottom-title {
  height: 20%;
  font-weight: bold;
  padding: 0.5rem 0;
}
.name-container {
  height: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name {
  font-size: 20px;
  font-weight: bold;
}
.volume {
  font-size: 16px;
}
.target-container {
  height: 60%;
  background-color: rgb(251, 229, 214);
  padding: 0.5rem;
}
.target {
  display: flex;
}
.body-fat-percentage-table {
  width: 100%;
  height: 80%;
}
.body-fat-percentage-table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 33.3%;
  background-color: rgb(218, 227, 243);
}
.table-head,
.table-content {
  width: 16%;
  height: 100%;
  text-align: center;
  border: 0.5px solid #bbb;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table-left {
  width: 4%;
  height: 100%;
  text-align: center;
  border: 0.5px solid #bbb;
}
.table-female,
.table-male {
  display: flex;
  height: 33.3%;
}

/* right-container */

.right-container-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.right-container-top {
  width: 100%;
  height: 20%;
  padding-bottom: 0.5rem;
}
.right-container-middle {
  width: 100%;
  height: 35%;
  display: flex;
  padding: 0.5rem 0;
}
.right-container-bottom {
  width: 100%;
  height: 45%;
  display: flex;
  padding-top: 0.5rem;
}
.right-container-middle-left {
  width: 60%;
  height: 100%;
  background-color: rgb(226, 240, 217);
  padding: 0.5rem;
  margin-right: 0.5rem;
}
.right-container-middle-right {
  width: 40%;
  height: 100%;
  background-color: rgb(218, 227, 243);
  padding: 0.5rem;
  margin-left: 0.5rem;
}
.right-container-bottom-left {
  width: 40%;
  height: 100%;
  background-color: rgb(255, 242, 204);
  padding: 0.5rem;
  margin-right: 0.5rem;
}
.right-container-bottom-right {
  width: 60%;
  height: 100%;
  margin-left: 0.5rem;
}
.right-container-bottom-right-top {
  width: 100%;
  height: 70%;
  background-color: rgb(251, 229, 214);
  padding: 0.5rem;
}
.right-container-bottom-right-bottom {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  font-weight: bold;
}
.right-container-bottom-img {
  width: 25%;
}
.right-container-bottom-img img {
  width: 100%;
}
.right-container-bottom-logo {
  width: 30%;
}
.right-container-bottom-logo img {
  width: 100%;
}
.chart {
  width: 100%;
}
.weight-chart,
.body-fat-chart,
.muscle-chart {
  height: 33.3%;
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
<style type="text/css" media="print">
/* @page {
  size: landscape;
} */
</style>
